import {
  CoinListItemResult,
  CoinListItemMapped,
} from "../../../types/api-results/coin-list-item";
import { toNumber } from "../format-number";

export const mapCoinListItems = (
  input: CoinListItemResult[] | undefined
): CoinListItemMapped[] | undefined => {
  if (!input) {
    return undefined;
  }

  const mappedItems = input
    .map((item) => ({
      id: item.id,
      slug: item.id.toLowerCase(),
      symbol: item.symbol || undefined,
      name: item.name || undefined,
      image: item.image || undefined,
      currentPrice: toNumber(item.current_price),
      marketCap: toNumber(item.market_cap),
      marketCapRank: toNumber(item.market_cap_rank),
      fullyDilutedValuation: toNumber(item.fully_diluted_valuation),
      totalVolume: toNumber(item.total_volume),
      high24h: toNumber(item.high_24h),
      low24h: toNumber(item.low_24h),
      priceChange24h: toNumber(item.price_change_24h),
      priceChangePercentage24h: toNumber(item.price_change_percentage_24h),
      marketCapChange24h: toNumber(item.market_cap_change_24h),
      marketCapChangePercentage24h: toNumber(
        item.market_cap_change_percentage_24h
      ),
      circulatingSupply: toNumber(item.circulating_supply),
      totalSupply: toNumber(item.total_supply),
      maxSupply: toNumber(item.max_supply),
      ath: toNumber(item.ath),
      athChangePercentage: toNumber(item.ath_change_percentage),
      athDate: item.ath_date ? new Date(item.ath_date) : undefined,
      atl: toNumber(item.atl),
      atlChangePercentage: toNumber(item.atl_change_percentage),
      atlDate: item.atl_date ? new Date(item.atl_date) : undefined,
      roi: item.roi
        ? {
            times: toNumber(item.roi.times),
            currency: item.roi.currency || undefined,
            percentage: toNumber(item.roi.percentage),
          }
        : undefined,
      lastUpdated: item.last_updated ? new Date(item.last_updated) : undefined,
      sparklineIn7d: item.sparkline_in_7d
        ? {
            price: item.sparkline_in_7d.price?.map((p) => toNumber(p)),
          }
        : undefined,
      priceChangePercentage24hInCurrency: toNumber(
        item.price_change_percentage_24h_in_currency
      ),
      priceChangePercentage7dInCurrency: toNumber(
        item.price_change_percentage_7d_in_currency
      ),
    }))
    .filter((item) => item.symbol && item.name) as CoinListItemMapped[];

  return mappedItems;
};
