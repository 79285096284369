import * as React from "react";
import { CoinListItemResult } from "../../../../types/api-results/coin-list-item";
import { CurrencySymbol } from "../../../../types/currency";
import { useCoinList } from "../../../hooks/query-hooks/use-coin-list";
import { formatCurrency } from "../../../utils/format-number";
import Column from "../../ui-library/column-system/column";
import Row from "../../ui-library/column-system/row";
import Container from "../../ui-library/container/container";
import { Colors } from "../../ui-library/design-tokens/colors";
import { Fonts } from "../../ui-library/design-tokens/fonts";
import { grid } from "../../ui-library/design-tokens/grid";
import Text from "../../ui-library/text/text";
import TradingViewAnalysis from "../coin/coin-stats-n-market/coin-stats/tradingview/tradingview-analysis";

interface CoinTechAnalysisProps {
  staticData: {
    allCoinListItem?: {
      nodes: CoinListItemResult[];
    };
  };
}

const CoinTechAnalysis: React.FC<CoinTechAnalysisProps> = ({ staticData }) => {
  const { data } = useCoinList({
    staticData: staticData.allCoinListItem?.nodes,
    pageSize: 100,
    hasChange: true,
  });

  return (
    <Container>
      <Row>
        {data
          ?.filter((c) => c.symbol !== "usdt")
          .map((coin) =>
            coin.symbol ? (
              <Column key={coin.id} spanLg={4}>
                <div css={{ marginBottom: grid(2) }}>
                  <Text
                    component="div"
                    textAlign="center"
                    weight={Fonts.Weight.Bold}
                    margin={{ bottom: grid(0.5) }}
                  >
                    {coin.name}
                  </Text>

                  <Text
                    component="div"
                    textAlign="center"
                    textSize={14}
                    weight={Fonts.Weight.SemiBold}
                    textColor={Colors.MidGrey}
                    margin={{ bottom: grid(1) }}
                  >
                    {formatCurrency({
                      value: coin.currentPrice,
                      currency: CurrencySymbol.USD,
                    })}
                  </Text>

                  <TradingViewAnalysis
                    id={coin.id}
                    symbol={coin.symbol}
                    height={400}
                    isNoCaption={true}
                  />
                </div>
              </Column>
            ) : null
          )}
      </Row>
    </Container>
  );
};

export default CoinTechAnalysis;
