import { CoinListItemResult } from "../../../types/api-results/coin-list-item";
import { mapCoinListItems } from "../../utils/data-mappings/map-coin-list-item";
import { getCoinListQueryUrl } from "../../../data/coingecko-endpoints";
import { FETCH_INTERVAL } from "../../constants/data-fetch";
import { useAxios } from "./use-axios";

interface UseCoinListInput {
  staticData?: CoinListItemResult[];
  page?: number;
  pageSize?: number;
  ids?: string[];
  hasChange?: boolean;
  isDefi?: boolean;
}

export const useCoinList = ({
  staticData,
  page = 1,
  pageSize = 100,
  ids,
  hasChange,
  isDefi,
}: UseCoinListInput) => {
  const url = getCoinListQueryUrl({
    pageSize,
    page,
    ids,
    hasChange,
    hasSparkline: true,
    isDefi,
  });

  const { data, error, loading, timestamp } = useAxios<CoinListItemResult[]>(
    url,
    {
      refetchInterval: FETCH_INTERVAL,
    }
  );

  const rawData = data || staticData || undefined;

  return {
    data: mapCoinListItems(rawData)?.slice(0, pageSize),
    error,
    loading,
    liveDataInitialLoading: loading && !data,
    timestamp,
  };
};
