import * as React from "react";
import { useInView } from "react-intersection-observer";
import { grid } from "../../../../../ui-library/design-tokens/grid";
import { Device } from "../../../../../ui-library/design-tokens/media-queries";

interface TradingViewAnalysisProps {
  id: string;
  symbol: string;
  height?: number;
  isNoCaption?: boolean;
}

// TODO: Tradingview duplicated names
const TradingViewAnalysis: React.FC<TradingViewAnalysisProps> = ({
  id,
  symbol,
  height,
  isNoCaption,
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const { inView, ref } = useInView({ triggerOnce: true });

  React.useEffect(() => {
    const scriptLink = document.createElement("script");

    scriptLink.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js";
    scriptLink.async = true;

    scriptLink.type = "text/javascript";
    scriptLink.text = `
        {
          "interval": "1D",
          "width": "100%",
          "isTransparent": false,
          "height": "100%",
          "symbol": "${symbol.toUpperCase()}USDT",
          "showIntervalTabs": true,
          "locale": "en",
          "colorTheme": "light",
          "container_id": "tv_ta_${id}"
        }
      `;

    if (inView) {
      containerRef.current?.appendChild(scriptLink);
    }
  }, [symbol, inView]);

  return (
    <div
      ref={ref}
      css={{
        height: height || grid(50),
        [Device.Mobile]: {
          height: height || grid(40),
          marginLeft: grid(-2),
          marginRight: grid(-2),
        },
      }}
    >
      <div
        ref={containerRef}
        id={`tv_ta_${id}`}
        className="tradingview-widget-container"
      >
        <div className="tradingview-widget-container__widget"></div>
        {!isNoCaption && (
          <div className="tradingview-widget-copyright">
            Technical Analysis for{" "}
            <a
              href={`https://www.tradingview.com/symbols/${symbol.toUpperCase()}/technicals/`}
              rel="noopener noreferrer"
              target="_blank"
            >
              <span className="blue-text">{symbol.toUpperCase()} Chart</span>
            </a>{" "}
            by TradingView
          </div>
        )}
      </div>
    </div>
  );
};

export default TradingViewAnalysis;
