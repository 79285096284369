import * as React from "react";
import { graphql, PageProps } from "gatsby";

import Layout from "../../components/layout/layout";
import SEO from "../../components/seo";
import { CoinTechAnalysisQuery } from "../../../graphql-types";
import CoinTechAnalysis from "../../components/pages/coins-tech-analysis/coins-tech-analysis";
import { NavTab } from "../../components/layout/navigation/navigation";

type CoinTechAnalysisPageProps = PageProps<CoinTechAnalysisQuery>;

const CoinTechAnalysisPage: React.FC<CoinTechAnalysisPageProps> = ({
  data,
}) => {
  return (
    <Layout activeTab={NavTab.Coins}>
      <SEO
        title="Coins tech analysis"
        meta={[{ name: "robots", content: "noindex" }]}
      />

      <CoinTechAnalysis staticData={data} />
    </Layout>
  );
};

export default CoinTechAnalysisPage;

export const query = graphql`
  query CoinTechAnalysis {
    allCoinListItem(
      limit: 100
      sort: { fields: [market_cap_rank], order: [ASC] }
    ) {
      nodes {
        id
        symbol
      }
    }
  }
`;
